<template>
  <div class="flex flex-col ">
    <div class="flex text-dark-gray justify-start items-center sm:justify-end">
      <form-kit
        id="accept-terms" ref="termsAccept" :value="value"
        :validation-messages="{
          accepted: 'Please accept the terms & conditions'
        }"
        data-cy="accept-terms"
        validation="accepted"
        type="checkbox"
        name="termsAndConditions"
      />
      <div class="text-sm font-semibold space-x-1 mt-1">
        <span>{{ $t('booking.agreeToThe') }}</span>
        <a :href="generalSettings.termsAndConditions" target="_blank" rel="noopener noreferrer" class="cursor-pointer text-primary hover:underline">{{ $t('booking.T&Cs') }}</a>
      </div>
    </div>
    <div class="flex sm:justify-end">
      <form-kit-messages :node="termsAccept?.node" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModelWrapper } from '@s/helpers/model-wrapper';
import { FormKitMessages } from '@formkit/vue';

const emit = defineEmits<Emits>();
const props = defineProps<Props>();
const { general: generalSettings } = window.wpSleepeasySettings;
const termsAccept = ref();
interface Props {
  // eslint-disable-next-line vue/no-unused-properties
  modelValue?: boolean
}

interface Emits {
  (e: 'update:modelValue', value: any): void
}

const value = useModelWrapper(props, emit, 'modelValue');
</script>
