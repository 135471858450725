import { createRouter, createWebHashHistory } from 'vue-router';
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '',
      name: 'Main Layout',
      component: () => import('../layouts/MainLayout.vue'),
      children: [
        {
          path: '/search-availability',
          name: 'SearchAvailability',
          component: () => import('../views/SearchAvailability.vue'),
          children: [
            {
              path: 'search-results',
              name: 'SearchResults',
              component: () => import('../views/components/search/SearchResults.vue'),
            },
            { path: 'room-listing', name: 'RoomListing', component: () => import('../views/components/rooms/RoomListing.vue') },
          ],
        },
        {
          path: '/make-booking',
          name: 'MakeBooking',
          component: () => import('../views/MakeBooking.vue'),
          children: [
            {
              path: 'booking-form',
              name: 'BookingForm',
              meta: { bookingPage: true },
              component: () => import('../views/components/booking/BookingForm.vue'),
            },
            {
              path: 'confirm-and-book',
              name: 'ConfirmAndBook',
              meta: { bookingPage: true },
              component: () => import('../views/components/booking/ConfirmAndBook.vue'),
            },
            {
              path: 'make-payment',
              name: 'MakePayment',
              component: () => import('../views/components/booking/MakePayment.vue'),
            },
            {
              path: 'booking-confirmed',
              name: 'BookingConfirmed',
              component: () => import('../views/components/booking/BookingConfirmed.vue'),
              meta: { bookingPage: true },
            },
            {
              path: 'booking-error',
              name: 'BookingError',
              component: () => import('../views/components/booking/BookingError.vue'),
            },
          ],
        },
        {
          path: '/make-enquiry',
          name: 'MakeEnquiry',
          component: () => import('../views/MakeEnquiry.vue'),
          children: [
            {
              path: 'enquiry-form',
              name: 'EnquiryForm',
              meta: { enquiryPage: true },
              component: () => import('../views/components/enquiry/EnquiryForm.vue'),
            },
            {
              path: 'enquiry-confirmed',
              name: 'EnquiryConfirmed',
              component: () => import('../views/components/enquiry/EnquiryConfirmed.vue'),
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from) => {
  if (to.path === '/' || (from.path === '/' && (to.name === 'SearchResults' || to.meta.bookingPage || to.meta.enquiryPage)))
    return { name: 'SearchAvailability' };
  if (to.name === 'SearchAvailability' && from.name === 'SearchResults') return false;
  if (to.name === 'PaymentError' && from.name !== 'MakePayment') return { name: 'SearchAvailability' };
});

export default router;
