<template>
  <router-view />
</template>

<script setup lang="ts">
import { useAvailabilityStore } from '@f/store/availabilityStore';

const availabilityStore = useAvailabilityStore();
onMounted(async () => {
  await availabilityStore.getListing();
});
</script>
