import '@s/assets/css/main.css';
import { installShared } from '@s/install-shared';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import 'v-calendar/dist/style.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AcceptTermsAndConditions from './views/components/shared/AcceptTermsAndConditions.vue';

export const app = createApp(App);
// const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
// const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

import.meta.env.PROD ?? Sentry.init({
  app,
  dsn: 'https://1ab6b08ba586469aa7078807c3fc02a6@o402293.ingest.sentry.io/6253359',
  replaysOnErrorSampleRate: 1,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.2,
  environment: 'production',
  // dist,
  // release,
});

app.use(router);
app.component('AcceptTermsAndConditions', AcceptTermsAndConditions);
installShared(app, 'wpSleepeasySettings').mount('#sleepeasy-app-booking');
